<template>
    <span v-if="props.value === 'A'">Temprano en la Mañana</span>
    <span v-if="props.value === 'B'">Final de la mañana</span>
    <span v-if="props.value === 'C'">Comienzo de la tarde</span>
    <span v-if="props.value === 'D'">Final de la tarde</span>
    <span v-if="props.value === 'E'">No importa</span>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    value: Number
});
</script>
